import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import { mapActions, mapState } from 'vuex';
import DocumentTable from '@/components/contract/search/elements/DocumentTable.vue';
import ContractSheetDialog from '@/components/contract/create/elements/ContractSheetDialog/ContractSheetDialog.vue';

export default {
	name: 'ProfilePreview',
	props: ['result'],
	data: () => ({
		contractDialog: false,
		documents: [
			{
				item: '4.0',
				date: '12/09/2022 14:22',
				user: 'Ernesto Quispe',
				comment: 'Actualización de precios..',
			},
		],

		responsibles_headers: [
			{
				text: 'Documento',
				value: 'name',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Fecha',
				value: 'updated_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],

		documents_headers: [
			{
				text: 'Archivo',
				value: 'file',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Versión',
				value: 'version',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Agregado',
				value: 'created_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Tipo',
				value: 'type',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Añadido por:',
				value: 'user_id',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Páginas',
				value: 'pages',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],

		direct_access_headers: [
			{
				text: 'Acceso',
				value: 'type',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Página',
				value: 'page',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],

		comments_headers: [
			{
				text: 'Añadido por:',
				value: 'user_id',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Fecha',
				value: 'created_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentario',
				value: 'comment',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],

		/*
		value: 'item',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Modificado',
				value: 'date',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Añadido por:',
				value: 'user',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
		
		*/
	}),
	computed: {
		...mapState('security', ['usersActive']),
		...mapState('document', ['template']),

		result_selected: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	created() {
		this.getUsersActive();
	},
	methods: {
		...mapActions('security', ['getUsersActive']),

		formatDate(date) {
			moment.locale('es');
			return date ? moment(date).format('LL') : null;
		},

		getUserName(id) {
			const user = (this.usersActive || []).find((user) => user.id == id);
			return user?.fullName || '';
		},

		getColor(expr) {
			switch (expr) {
				case 'PROVIDER':
					return 'yellow accent-1';
				case 'CLIENT':
					return 'green accent-2';
				case 'Pre - Contrato':
					return 'red lighten-4';
				case 'Contrato':
					return 'purple lighten-4';

				default:
					return 'grey';
			}
		},
		getValue(expr) {
			switch (expr) {
				case 'PROVIDER':
					return 'Proveedor';
				case 'CLIENT':
					return 'Cliente';
				default:
					return 'grey';
			}
		},
	},
	destroyed() {},
	components: {
		QrcodeVue,
		DocumentTable,
		ContractSheetDialog,
	},
};
